<template>
  <div class="data-generate face-edit">
    <div class="generate-content">
      <div class="generate-area">
        <div class="custom-block result-box">
          <h4 class="sub-title" :style="{'left': `${data.resultUrl ? beerRange : 0}%`}">{{data.resultUrl ? '原图' : '被攻击方'}}</h4>
          <div v-if="data.underAttackUrl" style="width: 100%; height: 100%">
            <div class="origin-url">
              <img :src="data.underAttackUrl" alt="" id="underAttackUrl">
            </div>
            <div class="beer-reveal" v-if="data.resultUrl">
              <h4 class="sub-title result" v-if="beerRange != 0">生成图</h4>
              <div class="box" :style="{'width': `${imgSize.width}px`, 'height': `${imgSize.height}px`}" >
                <div :style="{'width': `${resultWidth}%`, 'height': '100%', 'background-image': `url(${data.resultUrl})`, 'background-size': `${imgSize.width}px ${imgSize.height}px`}" class="result-url"></div>
              </div>
              <el-slider v-model="beerRange" :show-tooltip="false" class="beer-range"></el-slider>
              <span class="beer-handle" :style="{'left': `${beerRange}%`}" ></span>
            </div>
          </div>
          
          <div v-else class="uploader" @click="chooseExampleFile('underAttackUrl')">
            <img src="@/assets/img/icon-upload.png" alt="" class="icon-upload">
          </div>
          <div class="upload-desc" :class="data.loading ? 'disabled' : ''">
            <div class="upload-btn">
              <div class="upload-text" @click="chooseExampleFile('underAttackUrl')">
                <p class="text">上传图片</p>
              </div>
            </div>
            <!-- <p class="desc">请上传包含正面人脸的图片</p> -->
          </div>
          <div class="qr-scanner" v-if="data.loading">
            <div class="box">
              <div class="line"></div>
              <div class="angle"></div>
            </div>
            <p class="tip" v-if="!data.underAttackUrl">生成中</p>
          </div>
        </div>
        <div class="attribute-panel custom-block">
          <div class="item" :class="[data.activeAttribute == 'ageValue' ? 'active' : '', forbid ? 'disabled' : '']" @click="changeAttribute('ageValue')">
            <h4 class="sub-title grey">改变年龄</h4>
            <div class="slider-block">
              <span>变年轻</span>
              <el-slider v-model="ageValue" :show-tooltip="false" class="custom-slider" @change="changeAge" :class="data.activeAttribute == 'ageValue' ? 'active' : ''" :min="-6" :max="6" :disabled="forbid" :step="2"></el-slider>
              <span>变老</span>
            </div>
            <!-- <div class="radio-list">
              <div class="radio-item" v-for="(item, index) in ageList" :key="index" :class="data.ageValue == item.value ? 'active' : ''" @click="changeRadio('ageValue', item.value)">{{item.text}}</div>
            </div> -->
          </div>
          <div class="item" :class="[data.activeAttribute == 'smileValue' ? 'active' : '', forbid ? 'disabled' : '']" @click="changeAttribute('smileValue')" :disabled="forbid">
            <h4 class="sub-title grey">改变笑容</h4>
            <div class="radio-list">
              <div class="radio-item" v-for="(item, index) in smileList" :key="index" :class="data.smileValue == item.value ? 'active' : ''" @click="changeRadio('smileValue', item.value)">{{item.text}}</div>
            </div>
          </div>
          <div class="item" :class="[data.activeAttribute == 'activePupil' ? 'active' : '', forbid ? 'disabled' : '']" @click="changeAttribute('activePupil')" :disabled="forbid">
            <h4 class="sub-title grey">改变瞳孔</h4>
            <div class="radio-list">
              <div class="radio-item" v-for="(item, index) in pupilList" :key="index" :class="data.activePupil == item.value ? 'active' : ''" @click="changeRadio('activePupil', item.value)">{{item.text}}</div>
            </div>
          </div>
          <div class="item" :class="[data.activeAttribute == 'activeColor' ? 'active' : '', forbid ? 'disabled' : '']" @click="changeAttribute('activeColor')" :disabled="forbid">
            <h4 class="sub-title grey">改变发色</h4>
            <div class="color-list">
              <div class="color-item" v-for="(item, index) in colorList" :key="index" :style="{background: item.color}" :class="data.activeColor == item.value ? 'active' : ''" @click="changeColor(item.value)"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="footer">
      <el-button type="primary" @click="downloadImage" :disabled="btnDisabled">
        <span class="btn-inner">
          <img :src="btnDisabled ? iconExportDisabled : iconExport" alt="" width="20" height="20" class="icon-export">
          <span>导出</span>
        </span>
      </el-button>
    </div>

    <choose-example-radio
      v-if="chooseShowRadio"
      :dataList="customExampleList"
      @closeDialogItem="hideExampleRadio"
      :type="activeKey"
      :fileType="fileType"
      @onChoose="onChoose">
    </choose-example-radio>
  </div>
</template>

<script>
import store, {dispatch, ACTIONS, state, TYPE, commit} from '@/store';
import { Loading } from 'element-ui';
import { STORAGE, CODE_CLASS } from '@/utils/const';
import { downloadImage, goLoginPageAndInitData } from '@/utils';
import ChooseExampleRadio from "@/components/ChooseExampleRadio.vue";
import { setTimeout } from 'timers';

const ACTIVE_KEY = 'faceEditData';

const KEY_LIST = ['ageValue', 'smileValue', 'activePupil', 'activeColor'];
// 传给后端对应的值
const KEY_OBJ = {
  'ageValue': 'age',
  'smileValue': 'smile',
  'activePupil': 'eyetype',
  'activeColor': 'hair_color'
}
const COLOR_LIST = [
  {value: 'blonde', color: '#E5B230'},
  {value: 'purple', color: '#8D10E5'},
  {value: 'red', color: '#C52C2C'},
  {value: 'black', color: '#000000'}
];

export default {
  name: "face-edit",
  components: {
    "choose-example-radio": ChooseExampleRadio,
  },
  data() {
    return {
      activeKey: ACTIVE_KEY,
      chooseShowRadio: false,
      fileType: CODE_CLASS.moduleKey[ACTIVE_KEY],
      activeUploadKey: '', // 上传后需要对应的字段


      pupilList: [
        {text: '亚洲人瞳孔', value: 1},
        {text: '欧洲人瞳孔', value: 2},
      ],
      smileList: [
        {text: '微笑', value: 1},
        {text: '咧嘴笑', value: 3},
        {text: '大笑', value: 5},
      ],
      ageList: [
        {text: '变年轻', value: -5},
        {text: '变老', value: 5},
      ],
      colorList: COLOR_LIST,
      iconExport: require('@/assets/img/icon-export-white.png'),
      iconExportDisabled: require('@/assets/img/icon-export-disabled.png'),
      ageValue: 0,

      beerRange: 50,
      imgSize: {},
      totalWidth: 0,
    }
  },

  mounted() {
    this.getDemo();
    this.ageValue = state[ACTIVE_KEY] ? state[ACTIVE_KEY].ageValue : 0;
    this.getImgSize();
    setTimeout(() => {
      let box = document.querySelector(".result-box");
      this.totalWidth = box.getBoundingClientRect().width;
    }, 1000)
  },

  computed: {
    customExampleList() {
      return state.demoData[this.fileType];
    },
    data() {
      return state[ACTIVE_KEY] || {}
    },
    btnDisabled() {
      return !this.data.resultUrl;
    },
    imageUrl() {
      return this.data.resultUrl || this.data.underAttackUrl;
    },
    // 禁止操作
    forbid() {
      return !this.data.underAttackUrl || this.data.loading;
    },
    resultWidth() {
      let {totalWidth, beerRange, imgSize = {}} = this;
      let totalLeft = beerRange * totalWidth / 100;
      let diff = (totalWidth - imgSize.width) / 2;
      if (totalLeft <= diff || !imgSize.width) {
        return 0;
      } else {
        return ((totalLeft - diff) / imgSize.width) * 100;
      }
    },
  },

  methods: {
    getDemo() {
      dispatch(ACTIONS.getDemo, {fileType: this.fileType});
    },

    // 获取图片尺寸
    getImgSize() {
      setTimeout(() => {
        let mainImg = document.querySelector("#underAttackUrl");
        if (mainImg) {
          this.imgSize = {
            width: mainImg.width,
            height: mainImg.height,
          }
        }
      }, 1000)
    },

    // 初始化属性
    initAttribute(key, value) {
      let keyList = KEY_LIST.filter(itemKey => itemKey != key);
      keyList.map(itemKey => {
        if (['ageValue'].includes(itemKey)) {
          this.commitData({...this.data, [itemKey]: 0});
          this.ageValue = 0;
        } else {
          this.commitData({...this.data, [itemKey]: ''});
        }
      })

      // 不存在value就是重置，显示原图
      if (!value) {
        this.commitData({...this.data, resultUrl: ''});
      }

      if (key && value) {
        this.commitData({...this.data, [key]: value});
        setTimeout(() => {
          this.onCreate({...this.data, [key]: value})
        })
      }
    },

    // 改变属性
    changeAttribute(key) {
      let {activeAttribute} = this.data;
      if (this.forbid) {
        return;
      }
      if (activeAttribute != key) {
        this.commitData({...this.data, activeAttribute: key});
        this.initAttribute(key);
      }
    },

    // 改变年龄
    changeAge(value) {
      let {ageValue} = this.data;
      if (this.forbid) {
        return;
      }
      if (ageValue != value) {
        this.commitData({...this.data, ageValue: value});
        this.initAttribute('ageValue', value);
      }
    },

    // 改变瞳孔 笑容
    changeRadio(key, value) {
      if (this.forbid) {
        return;
      }
      if (this.data[key] == value) {
        this.commitData({...this.data, [key]: '', resultUrl: ''});
      } else {
        this.commitData({...this.data, [key]: value});
        this.initAttribute(key, value);
      }
    },

    // 改变发色
    changeColor(value) {
      let {activeColor} = this.data;
      if (this.forbid) {
        return;
      }
      if (activeColor == value) {
        this.commitData({...this.data, activeColor: '', resultUrl: ''});
      } else {
        this.commitData({...this.data, activeColor: value});
        this.initAttribute('activeColor', value);
      }
    },

    commitData(data) {
      commit(TYPE.SAVE_FACE_EDIT_DATA, data);
    },

    // 开始生成
    onCreate(data) {
      this.getImgSize();
      this.beerRange = 50;

      let {ageValue, smileValue, activePupil, activeColor, underAttackUrl, activeAttribute} = data;
      let params = {
        imageTarget: underAttackUrl,
        attrEpudit: KEY_OBJ[activeAttribute],
        attrStrength: ''
      }

      if (activeAttribute == 'activePupil') {
        params.attrEpudit = params.attrEpudit + `-${activePupil == 1 ? 1 : 0}`
      }

      if (['ageValue', 'smileValue'].includes(activeAttribute)) {
        // 年龄需要特殊处理：分别是-6、-4、-2、0、2、4、6传给后端需要-5、-3、-1、0、2、4、6
        if (activeAttribute == 'ageValue') {
          params.attrStrength = this.data[activeAttribute] < 0 ? this.data[activeAttribute] + 1 : this.data[activeAttribute];
        } else {
          params.attrStrength = this.data[activeAttribute];
        }
      }
      
      if (activeAttribute == 'activeColor') {
        params.hairColor = activeColor;
      }
      
      dispatch(ACTIONS.faceEdit, params);
    },

    downloadImage() {
      let {resultUrl} = this.data;
      resultUrl && downloadImage(resultUrl);
    },

    // 选择示例
    chooseExampleFile(key) {
      if (this.data.loading) {
        return;
      }
      this.chooseShowRadio = true;
      this.activeUploadKey = key;
    },
   
    hideExampleRadio(){
      this.chooseShowRadio = false;
    },

    // 选择
    onChoose(fileUrl) {
      this.chooseShowRadio = false;
      this.commitData({
        ...this.data,
        [this.activeUploadKey]: fileUrl,
        resultUrl: '',
        ageValue: 0,
        smileValue: '',
        activePupil: '',
        activeColor: '',
        activeAttribute: ''
      });
      this.ageValue = 0;
    },
  },
};
</script>

<style lang="less">
</style>
